import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './style'
import img from "~assets/image/customer-stories/feature-story.png";
import { SuperTag, Link } from '~components';

export default function ReviselyWidget(){
  
return(
      
<Widget>
  <Container>
  <Widget.Block>
    <Row className=" justify-content-lg-start">
    <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <Widget.Box>
        <Link to="/customer-stories"><Widget.MiniHeading><Widget.BackButton textColor="#000"><i class='fas fa-angle-left'></i>  Customer Stories</Widget.BackButton> </Widget.MiniHeading></Link>
          <Widget.Heading as="h2"> <SuperTag value="How Revisely Built a Healthy Sales Pipeline Using We-Connect"/> </Widget.Heading>
          
        </Widget.Box>
      </Col>
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <Widget.Image>
            <img  src={img} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
        </Widget.Image>
      </Col>
    </Row>
    </Widget.Block>

    <Widget.Block>
    <Row className="justify-content-lg-start">
    <Col xs="12" className="col-xl-4 col-lg-4 col-md-8 col-xs-10">
        <Widget.Card>
        <Widget.Company as="h2">Revisely</Widget.Company>
        <Widget.Text>Revisely is on a mission to help universities and secondary schools become more efficient in their grading efforts. With the EU's largest collection of free revision resources, Revisely helps teachers speed up the grading process while students receive higher-quality feedback.</Widget.Text>
        <p><a className="web" target="_blank" href="https://www.revisely.com/"> Company Website  <i class='fas fa-angle-right'></i></a></p>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Industry</b> </Widget.SubPara>
        <Widget.Text>Education</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Company Size</b> </Widget.SubPara>
        <Widget.Text>2-10 employees</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Features Used</b> </Widget.SubPara>
        <Widget.Text>Smart Sequences, Reporting & Analytics</Widget.Text>
        </Widget.Card>
      </Col>
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <Widget.ParaTwo>“One thing I like about We-Connect is the support team – they’re always available to help me with any questions I have.”</Widget.ParaTwo>
        <Widget.SubPara> <b>Sartou Djibril</b>, Head of Commercial Sales at Revisely </Widget.SubPara>

        <Widget.Title as="h2"><b>How Rivisely Uses LinkedIn:</b></Widget.Title>
        <Widget.Text>Prior to using We-Connect, the Head of Commercial Sales at Revisely, Sartou Djibril, and her team were manually inviting leads to connect. In this case, the leads were teachers, and Sartou’s goal was to foster a relationship with each lead before pitching Revisely to them.</Widget.Text>

        <Widget.Text>However, with thousands of teachers in the Netherlands alone, Sartou quickly realized that this process wasn’t as efficient as possible – meaning Revisely was missing out on potential sales.</Widget.Text>

        <Widget.Text>While looking for solutions to Revisely’s problem, Sartou learned about We-Connect from a friend in sales. She decided to implement the software into her team’s sales cycle to see if it could improve their prospecting efforts as it did for her colleague. Sartou made note of how easy it was to set up We-Connect, explaining, “Most of the things on We-Connect, I have been able to figure out myself. It’s quite easy to use.”
        </Widget.Text>

        <Widget.Title as="h2"><b>What We-Connect Powered Prospecting Looks Like at Revisely:</b></Widget.Title>
        <Widget.Text>Within two months of implementing We-Connect, Sartou managed to connect with over 500 teachers. She did this with Smart Sequences – creating and personalizing campaigns that targeted leads during regular school hours.</Widget.Text>
        <Widget.Text>Sartou noted, “In a very short period of time, you can build up a lot of interest and buzz around your products.” This quick turnaround helped Revisely land more leads right away. “The response rate has been amazing,” says Sartou, “The way [leads] request demos and react to our product is very needed.”</Widget.Text>
        <Widget.Text>One specific campaign that Revisely ran targeted 800 teachers in the Netherlands’ higher education system. This campaign saw a 20% connection rate with around 160 new connections. From these 160 connections, one deal generated $50,000 in revenue for Revisely.</Widget.Text>
        <Widget.Text>Sartou manages all of Revisely’s LinkedIn campaigns from We-Connect’s interactive dashboard. She says, “The dashboard is great! The visibility in how many people you’ve connected with, the success rate, and the conversion rate are really great.”</Widget.Text>

        <Widget.Title as="h2"><b>Building a Healthy Pipeline Using We-Connect:</b></Widget.Title>
        <Widget.Text>Revisely’s goal didn’t end with outreach. Instead, the company wanted to build a healthy sales pipeline and continue to nourish relationships with leads.</Widget.Text>
        <Widget.Text>Currently, Sartou continues to run invite connection campaigns, celebration greeting campaigns, and event campaigns. Her plan for using We-Connect in the future is to, “Build a healthy pipeline and run lead nurture campaigns.”</Widget.Text>
        <Widget.SubPara>Big thanks to Sartou Djibril and the team at Revisely for taking the time to collaborate with us on this case study.</Widget.SubPara>

        <Widget.CardTwo>
        <Widget.SubPara> Start your free trial and start kicking butt like this company did.</Widget.SubPara>
        <Widget.Button target="_blank" to="//account.we-connect.io/signup" className="btn btn-primary text-white">14 day free trial</Widget.Button>
        </Widget.CardTwo>
      </Col>
    </Row>
    </Widget.Block>
    
  </Container>
</Widget>

)
}