import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import { Helmet } from "react-helmet";
import ReviselyWidget from "~sections/featured-story/components/CS-widget/revisely"
//import scrollTo from 'gatsby-plugin-smoothscroll';
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function FeaturedStory() {
  return (
    <>
    <Helmet>
    <title>How Revisely Built a Healthy Sales Pipeline Using We-Connect</title>
    <meta name="description" content=" Revisely turned a manual process – reaching out to prospects on LinkedIn – into automation using We-Connect. Learn how they closed a 50,000 deal from one campaign here."/>
    <meta property="og:title" content="How Revisely Built a Healthy Sales Pipeline Using We-Connect"/>
    <meta property="og:description" content="Revisely turned a manual process – reaching out to prospects on LinkedIn – into automation using We-Connect. Learn how they closed a 50,000 deal from one campaign here."/>
    <meta property="og:image" content="https://we-connect.io/how-revisely-built-healthy-sales-pipeline-using-we-connect.png"/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/revisely"/>
    <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="How Revisely Built a Healthy Sales Pipeline Using We-Connect"/>
    <meta property="twitter:description" content="Revisely turned a manual process – reaching out to prospects on LinkedIn – into automation using We-Connect. Learn how they closed a 50,000 deal from one campaign here."/>
    <meta property="twitter:image" content="https://we-connect.io/how-revisely-built-healthy-sales-pipeline-using-we-connect.png"/> 
    </Helmet>
    <PageWrapper headerConfig={header}>
      <ReviselyWidget />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}